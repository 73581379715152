import { useEffect, useState } from "react";
import {UI_Table} from './ui-table';
import axios from "axios";
import { Autocomplete, TextField, Chip } from '@mui/material';
import {FindRange} from './findRange';
import CircularProgress from '@mui/material/CircularProgress';

const static_status_list = ['Open', 'Prospecting', 'Closed', 'Opening Soon']
const range_distances_guards = {'Meters': [100, 25000], 'Miles': [.1, 50], 'Minutes': [1, 50], 'Circle Radius Miles': [.1, 50], 'Circle Radius Meters': [100, 25000]}
const range_option_const = {Meters: ['1000', '10000', '25000'], Miles: ['1', '5', '10'], Minutes: ['5', '10', '15'], Custom: ['0'], 'Circle Radius Miles': ['1', '5', '10'], 'Circle Radius Meters': ['1000', '10000', '25000']}

export const MapMainFindings = ({exportInProgress, exportData, defaultTags, statusList = static_status_list, defaultRange, closeFindingLocation, idvalue, Competitor_type, sidebarUpdateLocationArea, getlocationrange}) => {
    // category => multiselect
    // edit location distance
    const range_types_options = ['Meters', 'Miles', 'Minutes', 'Circle Radius Miles', 'Circle Radius Meters']
    const range = idvalue.circle.range ? idvalue.circle.range : defaultRange;
    
    const [idvalFinal, setIdvalFinal] = useState(idvalue);
    const [rangeOptions, setRangeOptions] = useState(range_option_const[range.type]);
    const [rangeTypes, setRangeTypes] = useState(range.type);
    const [rangeDistance, setRangeDistance] = useState(range.distance ? range.distance.toString(): '0');
    const [calculatationError, setCalculationError] = useState('');
    const [calculationChanged, setCalculationChanged] = useState(false);
    const [calculationSearching, setCalculationSearching] = useState(false);
    const [settingsError, setSettingsError] = useState('');
    const [settingsErrorList, setSettingsErrorList] = useState([]);
    const [settingsChanged, setSettingsChanged] = useState(false);
    const [category, setCategory] = useState([]);
    const [location_total_agg, setLocation_total_agg] = useState([])
    const [name, setName] = useState(idvalue.name);
    const [brand, setBrand] = useState(idvalue.brand ? idvalue.brand : '');
    const [address, setAddress] = useState(idvalue.address);
    const [status, setStatus] = useState(statusList);
    const [tags, setTags] = useState(defaultTags);
    const [selectedStatus, setSelectedStatus] = useState(idvalue.status);
    const [selectedTags, setSelectedTags] = useState(idvalue.tags);
    const [initialRun, setInitialRun] = useState(true);


    useEffect(() => {
        if(idvalue) {
            getIdMain()
            setIdvalFinal(idvalue)
            setRangeOptions(range_option_const[range.type])
            setRangeTypes(range.type)
            setRangeDistance(range.distance ? range.distance.toString(): '0')
            setCalculationChanged(false)
            setSettingsErrorList([])
            setCalculationSearching(false)
            setName(idvalue.name)
            setBrand(idvalue.brand ? idvalue.brand : '')
            setAddress(idvalue.address)
            setStatus(statusList)
            setTags(defaultTags)
            setSelectedStatus(idvalue.status)
            setSelectedTags(idvalue.tags)

        }
    }, [idvalue]);

    useEffect(() => {
        setCategory(Competitor_type)
    }, [idvalue, Competitor_type])

    useEffect(() => {
        // console.log('rangeType: ', rangeTypes, rangeOptions)
        setRangeOptions(range_option_const[rangeTypes])
    }, [rangeTypes])

    const getIdMain = async () => {
        let token = localStorage.getItem("auth-token");
        const location_total_agg_req = await axios.post('/gettheurl/findlocationsmapped', {category, idvalue, bounds: []}, {headers:{ "auth-token": token}});
        const location_total_agg_data =  await location_total_agg_req.data.map((e) => ({...e, _id: e.Name_final}));
        setLocation_total_agg(location_total_agg_data)
        
    }

    const selectingTag =(event, newValue) => {
        if(selectedTags.length >= 3) return alert("You can select a maximum of 3 tags.");
        setSelectedTags(newValue)
        setSettingsChanged(true)
    }

    const settingsErrorFunc = (value, error) => {
        // console.log('settingsErrorFunc: ', value, error, value.length,  settingsErrorList.includes(error))
        if(value.length > 200){ 
            if(!settingsErrorList.includes(error)){
                var temp_settingsErrorList = settingsErrorList;
                temp_settingsErrorList.push(error)
                console.log('temp_settingsErrorList: ', temp_settingsErrorList)
                setSettingsErrorList(temp_settingsErrorList)
            }
        }else{
            var temp_settingsErrorList = settingsErrorList;
            temp_settingsErrorList = temp_settingsErrorList.filter((e) => e !== error)
            setSettingsErrorList(temp_settingsErrorList)    
        }
    }

    // const handleNameChange = (event) => {
    //     settingsErrorFunc(event.target.value, 'Name is too long.')
    //     setName(event.target.value);
    //     setSettingsChanged(true)
    // };

    const handleAddressChange = (event) => {
        settingsErrorFunc(event.target.value, 'Address length is too long.')
        setAddress(event.target.value);
        setSettingsChanged(true)

    }

    const handleBrandChange = (event) => {
        settingsErrorFunc(event.target.value, 'Brand length is too long.')
        setBrand(event.target.value);
        setSettingsChanged(true)

    }

    const senddata = async(idval_new ) => {
        let token = localStorage.getItem("auth-token");
        try{
        var senddata = await axios.post('/gettheurl/setvalue', 
        {  
            updater: true,
            brand,
            _id: idvalue._id,
            address: idval_new.address,
            range: idval_new.range,
            tags: idval_new.tags,
            status: idval_new.status,
            coordinates: idvalue.coordinates,
            name_prospect: idval_new.name, circle: idval_new.circle, data_circle: idval_new.data_circle}, {headers:{ "auth-token": token}});
        

        }catch(e){
            console.log(e)
            if(e.response.status == 400) window.location.href = '/login'
            if(e.response.status == 401) alert("You are out of locations to add. Upgrade your account in the settings")
        }
        setSettingsChanged(false)

        setIdvalFinal(idval_new)

    }

    const calculateChanges = async () => {
        if(calculatationError != '' || !calculationChanged) return;
        setCalculationSearching(true)

        var circle = await FindRange(idvalue.coordinates, {type: rangeTypes, distance: rangeDistance})
        console.log('circle: ', circle)
        // if(!circle) return setCalculationSearching(false);
        circle = circle.data.geometry
        var data_circle = await getlocationrange(circle.coordinates[0])
        // console.log('data_circle: ', data_circle)
        if(data_circle?.family?.zipcode_color){
            data_circle.family.zipcode_color = null;
        }
        if(data_circle?.total_age_sex?.zipcode_218){
            data_circle.total_age_sex.zipcode_218 = null;
        }
        let idval_new = idvalFinal
        idval_new.circle = circle;
        idval_new.data_circle = data_circle;
        idval_new.range = {type: rangeTypes, distance: rangeDistance}
        await senddata(idval_new)
        await sidebarUpdateLocationArea(name, circle, data_circle)
        setCalculationChanged(false)
        setCalculationSearching(false);
    }

    const saveChanges = async () => {
        if(!name || name === '') return alert('Name cannot be empty')
        if(!settingsChanged || settingsError != '') return;
        let idval_new = idvalFinal
        idval_new.tags = selectedTags;
        idval_new.address = address;
        idval_new.name = name;
        idval_new.status = selectedStatus;
        await senddata(idval_new)
        sidebarUpdateLocationArea(name)
    }

    const changeSelectedStatus = (event, newValue) => {
        setSelectedStatus(newValue)
        setSettingsChanged(true)

    }

    const numberWithCommas = (x) => {
        if(!x) return 'No Data'
        if(x === 0) return 'No Data'
        return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <div className="sidebar-realestate-total">
            <div className="box-price box-showmore" onClick={closeFindingLocation}><div id="box-goback">Back</div></div>

            <div className="mt-20 mb-20"> 
                <div className="current-locations-sidebar-box2">
                    <div className="current-locations-sidebar">Download Report</div>
                    <div className="center-content mt-20 mb-20">
                        {exportInProgress ?         <CircularProgress size={24} /> :
                        <div onClick={exportData} className="password-account ml-0 pt-3">Export</div>
                        }
                    </div>
                </div>
            </div>

            {(idvalue.revenue || idvalue.expense || idvalue.lease) && <div> 
                <div className="current-locations-sidebar-box2 pb-25">
                    <div className="current-locations-sidebar">Location Financials</div>
                    <div >
                            {idvalue.revenue && <div><div className="name-locations-sidebar cursor-default inline-block w-70">Revenue:</div> <div className="name-locations-sidebar cursor-default inline-block fw-normal">{numberWithCommas(idvalue.revenue)}</div></div>}
                            {idvalue.expense && <div><div className="name-locations-sidebar cursor-default inline-block  w-70">Expense:</div>  <div  className="name-locations-sidebar cursor-default inline-block fw-normal">{numberWithCommas(idvalue.expense)}</div></div>}
                            {idvalue.lease && <div ><div className="name-locations-sidebar cursor-default inline-block  w-70">Lease:</div> <div  className="name-locations-sidebar cursor-default inline-block fw-normal">{numberWithCommas(idvalue.lease)}</div></div>}
                    </div>
                </div>
            </div>}

            <div className="mt-20 mb-20"> <div className="current-locations-sidebar-box2"><div className="current-locations-sidebar">Location Range</div>
                <div className="ms-10">

                <Autocomplete
                    options={range_types_options}
                    getOptionLabel={(option) => option}
                    value={rangeTypes}
                    onChange={(event, newValue) => {
                        console.log('rangeTypes: ', newValue, rangeDistance, range_distances_guards[rangeTypes])
                        if(calculationSearching) alert('Calculating current range')
                        if(range_distances_guards[newValue]){
                            if(rangeDistance < range_distances_guards[newValue][0]){
                                setCalculationError('Range number is too small')  
                            }else if(!rangeDistance){
                                setCalculationError('Enter a range number')    
                            }else if(rangeDistance > range_distances_guards[newValue][1]){
                                setCalculationError('Range number is too large')    
                            }else{
                                setCalculationError('')
                            }
                        }
                        setRangeTypes(newValue)
                        setCalculationChanged(true)
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                        // style={{  color: 'white' }}
                        />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="standard"
                        label="Distance Type"
                        placeholder="Distance Type"
                        sx={{
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                            "& input": { fontSize: '12px' },
                            mt: 0.625,
                            mb: 0.625
                        }}
                        />
                    )}
                />
                <Autocomplete
                    options={rangeOptions}
                    freeSolo
                    getOptionLabel={(option) => option}
                    value={rangeDistance}
                    // onChange={(event, newValue) => {setRangeDistance(newValue)}}
                    onInputChange={(event, newInputValue) => {
                        console.log('rangeDistance: ', newInputValue)
                        if(!newInputValue.match(/^\d*\.?\d*$/)) return setCalculationError('Only numeric values for range')
                        if(calculationSearching) return setCalculationError('Calculating current range')

                        if(range_distances_guards[rangeTypes]){
                            if(newInputValue < range_distances_guards[rangeTypes][0]){
                                setCalculationError('Range number is too small')
                            }else if(!newInputValue){
                                setCalculationError('Enter a range number')    
                            }else if(newInputValue > range_distances_guards[rangeTypes][1]){
                                setCalculationError('Range number is too large')
                            }else{
                                setCalculationError('')
                            }
                        }
                        setRangeDistance(newInputValue);
                        if(!initialRun) setCalculationChanged(true)
                        setInitialRun(false)
                    }}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                        // style={{  color: 'white' }}
                        />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="standard"
                        label="Range Number"
                        placeholder="Range Number"
                        error={calculatationError !== ''}
                        helperText={calculatationError}
                        sx={{
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                            "& input": { fontSize: '12px' },
                            mt: 0.625,
                            mb: 2
                        }}
                        />
                    )}
                />
                <div className="main-mapping-save mb-20" onClick={calculateChanges}><div id="box-goback" className={`cursor-pointer ${calculatationError != '' || !calculationChanged ? 'button-disabled' : ''}`}>Calculate Region</div></div>

                </div>
            </div></div>
            <div className="mt-20 mb-20"> <div className="current-locations-sidebar-box2"><div className="current-locations-sidebar">Location: {name}</div>
            <div className="ms-10">
                <TextField
                onChange={handleBrandChange}
                value={brand}
                className='mb-5 mt-10 width-100'
                    variant="standard"
                    label="Brand Name"
                    placeholder="Brand Name"
                    sx={{
                        "& .MuiInputBase-input": { fontSize: '12px' }, 
                "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' }, 
                        mb: 0.625
                    }}
                />
                <TextField
                onChange={handleAddressChange}
                value={address}
                className='mb-5 mt-10 width-100'
                    variant="standard"
                    label="Location Address"
                    placeholder="Location Address"
                    sx={{
                        "& .MuiInputBase-input": { fontSize: '12px' }, // Sets font size of the input text
                "& .MuiInputLabel-root": { fontSize: '12px' },
                        "& input": { fontSize: '12px' }, // Adjust color as needed
                        mb: 0.625
                    }}
                />
                <Autocomplete
                    id="status-standard"
                    options={status}
                    freeSolo
                    getOptionLabel={(option) => option}
                    value={selectedStatus}
                    onChange={changeSelectedStatus}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} 
                        // style={{  color: 'white' }}
                        />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="standard"
                        label="Select Store Status"
                        placeholder="Current Status"
                        sx={{
                            "& .MuiInputLabel-root": { fontSize: '12px' },
                            "& input": { fontSize: '12px' },
                            mt: 0.625,
                            mb: 0.625
                        }}
                        />
                    )}
                />
                <Autocomplete
                    id="tags-standard"
                    options={tags}
                    multiple
                    freeSolo
                    getOptionLabel={(option) => option}
                    value={selectedTags}
                    onChange={selectingTag}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                        {...params}
                        variant="standard"
                        label="Select Store Tags"
                        placeholder="Current Tags"
                        sx={{ "& input": { fontSize: '12px' },
                        "& .MuiInputLabel-root": { fontSize: '12px' },
                        mt: 0.625,
                        mb: 2
                    }}  

                        />
                    )}
                />
                <div className="error-general">{settingsErrorList.length > 0 ? settingsErrorList[0] : ''}</div>
                <div className="main-mapping-save mb-20" onClick={saveChanges}><div id="box-goback" className={`cursor-pointer ${settingsErrorList.length > 0 || !settingsChanged ? 'button-disabled' : ''}`}>Save</div></div>

                </div>
            </div></div>

            {(location_total_agg.length > 0) && <UI_Table rows={location_total_agg} titles={[{title: 'Competitor Name', key:'Name_final'}, {title: 'Est. Total Locations', key: 'loc_count'}, {title: 'Locations On Map', key: 'count'}]}/>}
        </div>
    )
}