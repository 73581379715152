import { useEffect, useState } from "react";
import {UI_Table} from './ui-table';
import axios from "axios";
const static_status_list = ['Open', 'Prospecting', 'Closed', 'Opening Soon']
const range_distances_guards = {'Meters': [100, 25000], 'Miles': [.1, 50], 'Minutes': [1, 50], 'Circle Radius Miles': [.1, 50], 'Circle Radius Meters': [100, 25000]}
const range_option_const = {Meters: ['1000', '10000', '25000'], Miles: ['1', '5', '10'], Minutes: ['5', '10', '15'], Custom: ['0'], 'Circle Radius Miles': ['1', '5', '10'], 'Circle Radius Meters': ['1000', '10000', '25000']}

export const ExportDetails = ({defaultTags, statusList = static_status_list, defaultRange, closeFindingLocation, idvalue, Competitor_type, sidebarUpdateLocationArea, getlocationrange}) => {
    // category => multiselect
    // edit location distance
    const range = idvalue?.circle?.range ? idvalue.circle.range : defaultRange;
    
    const [idvalFinal, setIdvalFinal] = useState(idvalue);
    const [rangeOptions, setRangeOptions] = useState(range_option_const[range?.type]);
    const [rangeTypes, setRangeTypes] = useState(range?.type);
    const [rangeDistance, setRangeDistance] = useState(range?.distance ? range.distance.toString(): '0');
    const [category, setCategory] = useState([]);
    const [location_total_agg, setLocation_total_agg] = useState([])
    const [name, setName] = useState(idvalue?.name);
    const [brand, setBrand] = useState(idvalue?.brand ? idvalue.brand : '');
    const [address, setAddress] = useState(idvalue?.address);
    const [status, setStatus] = useState(statusList);
    const [tags, setTags] = useState(defaultTags);
    const [selectedStatus, setSelectedStatus] = useState(idvalue?.status);
    const [selectedTags, setSelectedTags] = useState(idvalue?.tags);

    useEffect(() => {
        console.log('idvalue: ', idvalue)
        if(idvalue) {
            // getIdMain()
            setIdvalFinal(idvalue)
            setRangeOptions(range_option_const[range?.type])
            setRangeTypes(range?.type)
            setRangeDistance(range?.distance ? range.distance.toString(): '0')
            setName(idvalue.name)
            setBrand(idvalue.brand ? idvalue.brand : '')
            setAddress(idvalue.address)
            setStatus(statusList)
            setTags(defaultTags)
            setSelectedStatus(idvalue.status)
            setSelectedTags(idvalue.tags)
            
            
            getSpreadSheetDownload(idvalue.data_circle, idvalue)
        }
    }, [idvalue]);

    const family_income = (age_values) => {
        if(!age_values) return;
        const header = ['Period', '$0 - 25', '$25 - 45', '$45 - 60', '$60 - 75', '$75 - 100', '$100 - 125', '$125 - 150', '$150 - 200', '$200+']
        const current = ['Current',  ...Object.values(age_values['2018'])]
        const previous = ['2012', ...Object.values(age_values['2012'])]
        return {sheet: 'Family Income Distribution',header, column_types: ['@', '0', '0', '0', '0', '0', '0', '0', '0', '0'], rows: [current, previous]}
    }

    const age_income_func = (age_values) => {
        if(!age_values) return;
        const header = ['Period', 'Under 25', '25 - 44', '44 - 64', '64 and Up']
        const current_data = age_values[2018]
        const previous_data = age_values[2012]
        const current = ['Current',  current_data['Under 25'], current_data['25 - 44'], current_data['44 - 64'], current_data['64 over']]
        const previous = ['2012', previous_data['Under 25'], previous_data['25 - 44'], previous_data['44 - 64'], previous_data['64 over']]
        return {sheet: 'Age Income Distribution',header, column_types: ['@', '"$"#,##0;[Red]"-$"#,##0', '"$"#,##0;[Red]"-$"#,##0', '"$"#,##0;[Red]"-$"#,##0', '"$"#,##0;[Red]"-$"#,##0'], rows: [current, previous]}
    }


    const age_race_func = (age_race_values) => {
        if(!age_race_values) return;
        const races = ["White", "Black/African American", "Hispanic/Latino", "Asian", "American Indian"];
        const age = {"25": "Under 25", "44": "25 - 44", "64": "44 - 64", "84": "64 and Up"}
        const aggregated = {};
    
        // Initialize the object with races and age groups
        races.forEach(race => {
            aggregated[race] = { "Under 25": 0, "25 - 44": 0, "44 - 64": 0, "64 and Up": 0 }
        });
        // Aggregate the data
        age_race_values.forEach(entry => {
            let groupName = age[entry.age];    
            // Sum the values for each race in the correct age group
            entry.eachvalue.forEach(raceEntry => {
                if (aggregated.hasOwnProperty(raceEntry.type)) {
                    aggregated[raceEntry.type][groupName] += raceEntry.value;
                }
            });
        });
        const rows = races.map(race => [race, ...Object.values(aggregated[race])]);
        return {sheet: 'Age Race Distribution', 'header': ['Race', 'Under 25', '25 - 44', '44 - 64', '64 and Up'], column_types: ['@', '0', '0', '0', '0'], 'rows': rows}
    }

    const age_spend_func = (age_spend_values) => {
        const spendCategories = ["Housing", "Food", "Entertainment", "Apparel", "Transportation", "Healthcare"];
        const age = {"25": "Under 25", "44": "25 - 44", "64": "44 - 64", "84": "64 and Up"}
        const aggregated = {};
        const counts = {};
    
        // Initialize the object with races and age groups
        spendCategories.forEach(spend => {
            aggregated[spend] = { "Under 25": 0, "25 - 44": 0, "44 - 64": 0, "64 and Up": 0 }
            counts[spend] = { "Under 25": 0, "25 - 44": 0, "44 - 64": 0, "64 and Up": 0 }
        });
    
        // Aggregate the data
        age_spend_values.forEach(entry => {
            let groupName = age[entry.age];    
            // Sum the values for each race in the correct age group
            Object.entries(entry.spent).forEach(([key, value]) => {
                if (entry.spent[key]) {
                    aggregated[key][groupName] += value;
                    counts[key][groupName] += 1;
                }
            });
        });
        function safeDivide(numerator, denominator) {
            return denominator ? Math.round(numerator / denominator) : 0;
        }
        const averages = {};
        spendCategories.forEach(category => {
            averages[category] = {
                "Under 25":  safeDivide(aggregated[category]["Under 25"], counts[category]["Under 25"]),
                "25 - 44":   safeDivide(aggregated[category]["25 - 44"], counts[category]["25 - 44"]),
                "44 - 64":   safeDivide(aggregated[category]["44 - 64"], counts[category]["44 - 64"]),
                "64 and Up": safeDivide(aggregated[category]["64 and Up"], counts[category]["64 and Up"])
            };
        });
        const rows = spendCategories.map(spend => [spend, ...Object.values(averages[spend])]);
        return {sheet: 'Age Spend Distribution', 'header': ['Race', 'Under 25', '25 - 44', '44 - 64', '64 and Up'], column_types: ['@', '"$"#,##0;[Red]"-$"#,##0', '"$"#,##0;[Red]"-$"#,##0', '"$"#,##0;[Red]"-$"#,##0', '"$"#,##0;[Red]"-$"#,##0'], 'rows': rows}
    }

    const commercial_shops_func = (shops_near) => {
        if(!shops_near) return;
        const rows = shops_near.map((e) => [e.Name_final, e.average_rent_sqft, e.loc_overlap_count])
        return {sheet: 'Shopping Centers In Range', 'header': ['Name', 'Est. Rent SQFT', 'Est. Locations Count'], column_types: ['@', '"$"#,##0.00;[Red]"-$"#,##0.00', '0'], 'rows': rows}
    }

    const commercial_locations_func = (location_near) => {
        if(!location_near) return;
        const rows = location_near.map((e) => [e.Name_final, e.count, e.loc_count])
        return {sheet: 'Shops In Range', 'header': ['Name', 'Count', 'Overall Est. Count'], column_types: ['@', '0', '0'], 'rows': rows}
    }

    const getSpreadSheetDownload = async (idvalue, idvalue_raw) => {
        const location_information = {sheet: 'Location Information', header: [`Name: ${idvalue_raw.name}`], rows: [[`Address: ${idvalue_raw.address}`], [`Current Status: ${idvalue_raw.status}`], [`Range: ${idvalue_raw.circle?.range?.distance} ${idvalue_raw.circle?.range?.type}`], [`Revenue: ${idvalue_raw.revenue}`], [`Expense: ${idvalue_raw.expense}`], [`Lease: ${idvalue_raw.lease}`]]}
        const age_income = age_income_func(idvalue?.age_income_overall);
        // const age_race = age_race_func(idvalue?.psychograph?.total_interest);
        const age_income_spent = age_spend_func(idvalue?.psychograph?.total_interest);
        const family = family_income(idvalue?.family_values);
        const commercial_shops = commercial_shops_func(idvalue?.commercial_data?.shops_near);
        const commercial_locations = commercial_locations_func(idvalue?.commercial_data?.location_total_agg);
        const export_excel = await axios.post('/gettheurl/export', {location_information, age_income, age_income_spent, family, commercial_shops, commercial_locations}, {
            responseType: 'blob'
        })
        
        const url = window.URL.createObjectURL(new Blob([export_excel.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download =  `mapchise_${idvalue_raw.name}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        // const degree = idvalue.degree;
        // const occupation = idvalue.occupation;

    }

    const numberWithCommas = (x) => {
        if(!x) return 'No Data'
        if(x === 0) return 'No Data'
        return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
    <div className="sidebar-realestate-total">
        <div className="mt-20 mb-20">
            <div className="current-locations-sidebar-box2">
                <div className="current-locations-sidebar"><h2 className="font-weight600 display-inline-block">{name}</h2></div>
                <div className="ms-10">
                    <div className="mt-5 mb-5" >Brand Name: <div className="font-weight600 display-inline-block">{brand}</div></div>
                    <div className="mt-5 mb-5">Location Address: <div className="font-weight600 display-inline-block">{address}</div></div>
                    <div className="mt-5 mb-5">Status: <div className="font-weight600 display-inline-block">{selectedStatus}</div></div>
                    {selectedTags?.length > 0 && <div className="mt-5 mb-5">Tags: <div className="font-weight600 display-inline-block">{selectedTags?.join(', ')}</div> </div>}
                    <div className="mt-5 mb-5">Distance Type: <div className="font-weight600 display-inline-block">{rangeTypes}</div></div>
                    <div className="mt-5 mb-5">Range Distance: <div className="font-weight600 display-inline-block">{rangeDistance}</div></div>
                    {idvalue.revenue && <div className="mt-5 mb-5">Revenue: <div className="font-weight600 display-inline-block">{numberWithCommas(idvalue.revenue)}</div></div>}
                    {idvalue.expense && <div className="mt-5 mb-5">Expense: <div className="font-weight600 display-inline-block">{numberWithCommas(idvalue.expense)}</div></div>}
                    {idvalue.lease && <div className="mt-5 mb-5">Lease: <div className="font-weight600 display-inline-block">{numberWithCommas(idvalue.lease)}</div></div>}
                </div>
            </div>
        </div>
    </div>
    )
}